import { useNavigate, useLocation } from "react-router-dom";
import { checkLoginFromJikji } from "../core/api";
import { setLoginCookie } from "../core/cookie";
import { useEffect } from "react";

export default function SSOCheck() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const useremail = queryParams.get("usermail");
    const userlkey = queryParams.get("userlkey");
    const page = queryParams.get("page");

    window.history.replaceState({}, null, location.pathname);

    // Use the received values here
    // ssoLoginProcess(useremail, userlkey, true);
    const ssoLoginProcess = async (email, password, keepLogin, page) => {
        const user = {
            email,
            password,
            keepLogin,
            page,
        };
    
        // console.log("user", user);
        let formdata = new FormData();

        formdata.append("userId", email);
        formdata.append("userlkey", userlkey);

    
        const result = await checkLoginFromJikji(formdata);
        if (result) {
            const returnInt = result.returnInt;
            // console.log("로그인", result);
    
            if (returnInt === 1) {
                const accessToken = result.row.accessToken;
                const refreshToken = result.row.refreshToken;
                setLoginCookie(keepLogin, accessToken, refreshToken);
                if(page.toLowerCase() === 'contentlist') {
                    navigate("/library/upload");
                } else if(page.toLowerCase() === 'templatelist') {
                    navigate("/jikji_template");
                } else {
                    navigate("/library/upload");
                }
            } else {
                navigate("/sso_fail");
            }
        
        } else {
            navigate("/sso_fail");
        }
    }

    useEffect(() => {
        // console.log('SSOCheck');
        
        // console.log('useremail', useremail);
        // console.log('userlkey', userlkey);
        // console.log('page', page);

        ssoLoginProcess(useremail, userlkey, true, page);

    }, []);
    return null;
}