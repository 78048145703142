import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, forwardRef, useRef, Fragment } from "react";
import styled from "styled-components";
import imgLogo from "../images/logo_kpub.png";
import { ReactComponent as Icon_bookshelf } from "../images/components/header/icon_bookshelf.svg";
import { ReactComponent as Icon_search } from "../images/components/header/icon_search.svg";
import { ReactComponent as Icon_menu } from "../images/components/header/icon_menu.svg";
import { ReactComponent as LOGO_KPUB } from "../images/components/header/LOGO_KPUB.svg";
import { ReactComponent as LOGO_KPUB_M } from "../images/components/header/LOGO_KPUB_mobile.svg";
import arrowDown from "../images/common/button/button_arrow_down.svg";
import { useIsLogin, useUser } from "../util";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { delLoginCookie } from "../core/cookie";
import ProfileIcon from "./ProfileIcon";
import { checkLogoutToken } from "../core/api";
import Hashtag from "./Hashtag";

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  min-width: 1200px;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 30;
  > .innerWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    width: 1200px;
    padding: 0 16px;
    @media ${({ theme }) => theme.device.tablet} {
      min-width: 100%;
      width: 100%;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    .innerWrap {
      width: 100%;
    }
  }
  /* 메뉴 */
  .menuContainer_back {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
  }
  .menuContainer_maxWidth {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    margin: 0 auto;
  }
  .menuContainer {
    // display: flex;
    // flex-direction: column;
    // position: absolute;
    // top: 50px;
    // right: 0px;
    // width: 310px;
    // padding: 8px 0;
    // background-color: #fff;
    // border: 1px solid #a8a4a4;
    // border-radius: 4px;
    // z-index: 2;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 55px;
    right: 0px;
    width: 200px;
    padding: 8px 0px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(168, 164, 164);
    border-radius: 4px;
    z-index: 2;
    ul {
      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
      padding: 15px 32px;
      li {
        padding: 9px 0;
        font-size: 18px;
        &.off {
          opacity: 0.3;
          pointer-events: none;
        }
        &:hover {
          color: #f74551;
        }
        a {
          display: inline-block;
          width: 100%;
        }
        span {
          cursor: pointer;
        }
      }
    }
  }
  /* -------- */
`;
const LogoButton = styled.div`
  /* width: 68px;
  height: 20px;
  background: url(${imgLogo}) center / 100% auto no-repeat; */
`;
const RightButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  @media ${({ theme }) => theme.device.tablet} {
    gap: 16px;
  }
  > * {
    /* padding: 2px 0 0 0;
    margin: 0 12px; */
    cursor: pointer;
  }
`;
const TextButton = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size: 16px;
  line-height: 19px;
  color: #303441;
  &:hover {
    color: #f74551;
  }
  @media ${({ theme }) => theme.device.tablet} {
    font-family: "Pretendard-Regular";
    font-size: 18px;
    line-height: 22px;
    color: #0f0f10;
  }
`;
const SearchButton = styled.div`
  /* &:hover {
    svg path {
      fill: #f74551;
    }
  } */
  /* &:hover {
    fill: #000;
  } */
  svg.on path {
    fill: #f74551;
  }
`;
const LoginButton = styled.div`
  svg {
    width: 30px;
    height: 30px;
    @media ${({ theme }) => theme.device.tablet} {
    }
    width: 32px;
    height: 32px;
  }
  &:hover {
    svg path {
      fill: #f74551;
    }
  }
`;
// const MenuButton = styled.div`
//   background: url(${imgMenu}) center / 100% auto no-repeat;
// `;

const SearchForm = styled.form`
  position: relative;
  display: flex;
  width: 560px;
  padding: 0 6px 0 16px;
  margin: 0 10px;
  border: 0;
  border-radius: 36px;
  background-color: #fdf6f7;
  .searchBox {
    width: 100%;
    height: 36px;
    /* margin: 0 12px; */
    border: none;
    background-color: #fdf6f7;
    font-size: 16px;
    outline: none;
    @media ${({ theme }) => theme.device.tablet} {
      height: 32px;
      font-size: 16px;
      line-height: 19px;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin: 0 16px;
    /* font-size: 13px; */
  }
  button {
    /* position: absolute;
    right: 23px; */
    border: 0;
    background-color: transparent;
    cursor: pointer;
    svg {
      position: relative;
      top: 1px;
    }
  }
`;

// ----------- 다크 모드 -----------
// <button onClick={() => setTheme(theme === "light" ? "dark" : "light")}>
//   {theme === "light" ? "dark" : "light"}
// </button>
// --------------------------------

function Modal({ children, shown, close }) {
  return shown ? (
    <div
      className="menuContainer_back"
      onClick={() => {
        // close modal when outside of modal is clicked
        close();
      }}
    >
      <div className="menuContainer_maxWidth">
        <div
          className="menuContainer"
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </div>
  ) : null;
}

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  // const { user } = useIsLogin();
  const { user } = useUser();
  const [modalShown, toggleModal] = useState(false);
  const [search, setSearch] = useState(false);

  // 반응형
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  const body = document.querySelector("body");

  const onValid = (form) => {
    
    //alert(location.pathname); 현재 페이지 확인
    //2023-10-25 이호경 현재페이지가 템플릿, 템플릿검색가 아니라면 기존 검색 사용.
    if(location.pathname!=="/template" && location.pathname!=="/searchTemplate"){
      form.preventDefault();
      let userInput = form.target[0].value;
      if (!userInput) return;
      navigate(`/search?keyword=${userInput}`);
      userInput = "";
      body.style.overflow = "auto";
      setSearch(false);
    }else{
      form.preventDefault();
      let userInput = form.target[0].value;
      if (!userInput) return;
      navigate(`/searchTemplate?keyword=${userInput}`);
      userInput = "";
      body.style.overflow = "auto";
      setSearch(false);
    }
    
  };
  const onClickShow = (e) => {
    var _this = e.target;
    if (search) {
      _this.classList.remove("on");
      body.style.overflow = "auto";
      setSearch(false);
    } else {
      _this.classList.add("on");
      if (tablet) {
        body.style.overflow = "hidden";
      }
      setSearch(true);
    }
  };
  const setDefault = () => {
    //클릭했을때 카테고리 전체 초기화
  };
  const logOut = async () => {
    const result = await checkLogoutToken();
    if (result.returnInt === 1) {
      delLoginCookie();
      navigate("/");
      // console.log("로그아웃", result);
      window.location.reload();
    }
  };
  const myLibrary = () => {
    navigate("/library");
    window.location.reload();
  };
  return (
    <>
      <HeaderWrap>
        <div className="innerWrap">
          <Link to={`/`}>
            <LogoButton>{!tablet ? <LOGO_KPUB /> : <LOGO_KPUB_M />}</LogoButton>
          </Link>
          <SearchForm className="noDesign" onSubmit={onValid}>
            <input className="searchBox" placeholder="검색" />
            <button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#F74551"
                  d="M17.0067 15.0067H15.9534L15.58 14.6467C16.8867 13.1267 17.6734 11.1533 17.6734 9.00666C17.6734 4.22 13.7934 0.339996 9.00669 0.339996C4.22003 0.339996 0.340027 4.22 0.340027 9.00666C0.340027 13.7933 4.22003 17.6733 9.00669 17.6733C11.1534 17.6733 13.1267 16.8867 14.6467 15.58L15.0067 15.9533V17.0067L21.6734 23.66L23.66 21.6733L17.0067 15.0067ZM9.00669 15.0067C5.68669 15.0067 3.00669 12.3267 3.00669 9.00666C3.00669 5.68666 5.68669 3.00666 9.00669 3.00666C12.3267 3.00666 15.0067 5.68666 15.0067 9.00666C15.0067 12.3267 12.3267 15.0067 9.00669 15.0067Z"
                />
              </svg>
            </button>
          </SearchForm>
          <RightButton>
            {!tablet && (
              <>
                <Link to={`/template`}>
                  <TextButton>템플릿</TextButton>
                </Link>
                <Link to={`/download`}>직지다운로드</Link>
                {user ? (
                  <Link to={`/library`}>
                    <TextButton>마이룸</TextButton>
                  </Link>
                ) : (
                  <Link to={`/login`} state={{ mode: "login" }}>
                    로그인
                  </Link>
                )}

                {user ? null : (
                  <Link to={`/login`} state={{ mode: "signUp" }}>
                    회원가입
                  </Link>
                )}
              </>
            )}
            <LoginButton
              onClick={() => {
                toggleModal(!modalShown);
              }}
            >
              {user ? <ProfileIcon /> : <Icon_menu />}
            </LoginButton>
          </RightButton>

          <Modal
            shown={modalShown}
            close={() => {
              toggleModal(false);
            }}
          >
            {!tablet && (
              <ul>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`/request`}>컨텐츠제작문의</Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`/contentGuide`}>솔루션문의</Link>
                </li>
                {/* <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`/template`}>템플릿</Link>
                </li>                 */}
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                  className={user ? "" : "off"}
                ></li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`https://me2.do/FpM308YM`} target="_blank">
                    매뉴얼
                  </Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link
                    to={`http://tovsoft.com:8080/jikji/tutorial`}
                    target="_blank"
                  >
                    튜토리얼
                  </Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link
                    to={`https://www.youtube.com/playlist?list=PLONYAo8uJT6zm7lEXOGVDoeLNC4vv124S`}
                    target="_blank"
                  >
                    유튜브
                  </Link>
                </li>
                {user ? (
                  <li
                    onClick={() => {
                      toggleModal(!modalShown);
                    }}
                    className={user ? "" : "off"}
                  ></li>
                ) : null}
                {user ? (
                  <li
                    onClick={() => {
                      toggleModal(!modalShown);
                    }}
                    className={user ? "" : "off"}
                  >
                    <Link to={`/account`}>정보 수정</Link>
                  </li>
                ) : null}
                {user ? (
                  <li
                    onClick={() => {
                      toggleModal(!modalShown);
                    }}
                  >
                    <span onClick={logOut}>로그아웃</span>
                  </li>
                ) : null}
              </ul>
            )}
            {tablet && (
              <ul>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`/contentGuide`}>콘텐츠 만들기</Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`/download`}>직지다운로드</Link>
                </li>

                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                  className={user ? "" : "off"}
                ></li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`/request`}>컨텐츠제작문의</Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`/solution`}>솔루션문의</Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`/template`}>템플릿</Link>
                </li>                
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                  className={user ? "" : "off"}
                ></li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link to={`https://me2.do/FpM308YM`} target="_blank">
                    매뉴얼
                  </Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link
                    to={`http://tovsoft.com:8080/jikji/tutorial`}
                    target="_blank"
                  >
                    튜토리얼
                  </Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  <Link
                    to={`https://www.youtube.com/playlist?list=PLONYAo8uJT6zm7lEXOGVDoeLNC4vv124S`}
                    target="_blank"
                  >
                    유튜브
                  </Link>
                </li>
                <li
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                  className={user ? "" : "off"}
                ></li>
                {user ? null : (
                  <li
                    onClick={() => {
                      toggleModal(!modalShown);
                    }}
                  >
                    <Link to={`/login`} state={{ mode: "login" }}>
                      로그인
                    </Link>
                  </li>
                )}
                {user ? null : (
                  <li
                    onClick={() => {
                      toggleModal(!modalShown);
                    }}
                  >
                    <Link to={`/login`} state={{ mode: "signUp" }}>
                      회원가입
                    </Link>
                  </li>
                )}
                {user ? (
                  <li
                    onClick={() => {
                      toggleModal(!modalShown);
                    }}
                    className={user ? "" : "off"}
                  >
                    <Link to={`/library`}>마이룸</Link>
                  </li>
                ) : null}                
                {user ? (
                  <li
                    onClick={() => {
                      toggleModal(!modalShown);
                    }}
                    className={user ? "" : "off"}
                  >
                    <Link to={`/account`}>정보 수정</Link>
                  </li>
                ) : null}
                {user ? (
                  <li
                    onClick={() => {
                      toggleModal(!modalShown);
                    }}
                  >
                    <span onClick={logOut}>로그아웃</span>
                  </li>
                ) : null}
              </ul>
            )}
          </Modal>
        </div>
      </HeaderWrap>
    </>
  );
}
